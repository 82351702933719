<template>
  <div id="campaignHero" class="container relative mt-8 mb-10 text-center rounded">
    <div class="hidden">
        <Advert :data="{ id: 'slot-8-campaign', instance: 'all6' }"></Advert>
    </div>

    <a
      id="campaignHero__link-href"
      aria-hidden="true"
      class="inline-block rounded cursor-default pointer-events-auto"
      tabindex="-1"
      @click.prevent="linkSelect({ga:'campaignHeroAdLinkClick', ga4:'hero ad link'})"
    >
      <span id="campaignHero__link-desc" class="sr-only"></span>
      <img
        v-for="(img, idx) in items"
        :key="`campaignHero-image-${idx}`"
        :id="`campaignHero__image--${img.id}`"
        aria-hidden="true"
        alt=""
        :width="img.width"
        :class="img.css"
        class="rounded mx-auto shadow opacity-0"
      />
    </a>
  </div>
</template>

<script>
export default {
    data() {
    return {
        items: [
            {
                id: "mobile",
                width: 335,
                height: 310,
                css: "md:hidden",
            },
            {
                id: "tablet",
                width: 708,
                height: 310,
                css: "hidden md:block lg:hidden",
            },
            {
                id: "desktop",
                width: 1100,
                height: 254,
                css: "hidden lg:block",
            },
        ]
    }
  },
  components: {
    Advert: () =>
        import(/* webpackChunkName: "tsm-adslot" */ "@/components/Adslot.vue"),
  },
  methods: {
    linkSelect(obj) {
      const realLink = document.getElementById('campaignHero__link-href')
      const newUrl = realLink.href
      const newTarget = realLink.target

      if(newUrl && newTarget) {
        this.$generalClick({
          category: "Conversion",
          label: obj.ga,
        });

        this.$ga4GeneralClick({
          event: 'ad',
          module: obj.ga4,
          action: 'click',
        })

        window.open(newUrl, newTarget);
      }      
    }
  },
  mounted() {
    this.$root.$on("takeoverImageClick", (label) => {
      this.linkSelect(label)
    });
  }
};
</script>
